import React, { useState } from "react";
import { query, getDocs, where, collection } from "firebase/firestore";
import styled from "styled-components";
import { db } from "../config/firebase";
import useUserImages from "../customHooks/useUserImages";
import ItemSelectedModal from "../components/ItemSelectedModal";
import ImageGrid from "../components/ImageGrid";
import useAddToLook from "../customHooks/useAddToLook";

const WardrobeHeader = styled.div`
  padding: 1rem;
  /* Add any other styles you want for the specific div */
`;

const WardrobeContainer = styled.div`
  // width: calc(92%);
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #333;
  margin: 0 0.5rem;
  border-radius: 5px;
  overflow: auto;
`;

const LoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set the height to 100% to fill the entire container */
  text-align: center;
  font-size: normal;
  /* Add any other styles you want for the loading message */
`;

const Wardrobe = ({ user, searchTerm }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [looks, setLooks] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const { userImages, loading, error } = useUserImages(user, searchTerm);

  const addToLook = useAddToLook();

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
    if (user && user.uid) {
      fetchLooks(user.uid);
    } else {
      console.error("User ID is undefined.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveToLook = async (selectedLookId) => {
    const success = await addToLook(selectedLookId, selectedImage);
    if (success) {
      setSelectedImage(null);
      setIsModalOpen(false);
    } else {
      console.error("Failed to add image to look or look not found");
    }
  };

  const fetchLooks = async (userId) => {
    const q = query(collection(db, "looks"), where("userId", "==", userId));
    const looksSnapshot = await getDocs(q);
    const fetchedLooks = [];
    looksSnapshot.forEach((doc) =>
      fetchedLooks.push({ id: doc.id, ...doc.data() })
    );
    setLooks(fetchedLooks);
  };

  return (
    <WardrobeContainer>
      {loading ? (
        <LoadingMessage>Loading...</LoadingMessage>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <WardrobeHeader>WARDROBE</WardrobeHeader>
          <ImageGrid userImages={userImages} openModal={openModal} />
          <ItemSelectedModal
            isOpen={isModalOpen}
            looks={looks}
            onSave={handleSaveToLook}
            onCancel={closeModal}
          />
        </>
      )}
    </WardrobeContainer>
  );
};

export default Wardrobe;
