import { useState, useEffect } from 'react';
import { onSnapshot, doc, getFirestore, getDoc } from '@firebase/firestore';

const useUserImages = (user, searchTerm) => {
  const [userImages, setUserImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(getFirestore(), "users", user.uid),
      (docSnapshot) => {
        if (docSnapshot.exists() && docSnapshot.data().imageRefs) {
          const imageRefs = docSnapshot.data().imageRefs;
  
          Promise.all(imageRefs.map((ref) => getDoc(ref)))
            .then((imageDocs) => {
              const imageUrls = imageDocs
                .filter((doc) => searchTerm ? doc.data().imageName.includes(searchTerm) : true)
                .map((doc) => doc.data().imageURL);
  
              setUserImages(imageUrls);
            })
            .catch((error) => {
              setError(error.message);
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [user, searchTerm]);

  return { userImages, loading, error };
};

export default useUserImages;
