import React, { useState } from "react";
import styled from "styled-components";
import Wardrobe from "./Wardrobe";

const WardrobeContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  background-color: transparent;
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 0 1.5rem;
  border-radius: 5px;
  overflow: auto;
  flex-wrap: wrap;
  align-content: stretch;
`;

const FormContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  background-color: #fff;
  width: 100%;
  margin: 3rem;
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
`;

const FormField = styled.input`
  margin-bottom: 0.5rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
`;

const FormTextArea = styled.textarea`
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
  margin-bottom: 1.5rem;
  height: 3rem;
  font-family: sans-serif;
`;

const FormButton = styled.button`
  display: flex;
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: start;
  border: none;
  background: white;
  font-size: large;
`;

const AddLookForm = ({ onSave, onCancel }) => {
  const [lookName, setLookName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ lookName, description });
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        <FormField
          type="text"
          placeholder="Name"
          value={lookName}
          onChange={(e) => setLookName(e.target.value)}
          required
        />
        <FormTextArea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <WardrobeContainer>
          <FormButton type="button" onClick={onCancel}>
            BACK
          </FormButton>
          <FormButton type="submit">ADD</FormButton>
        </WardrobeContainer>
      </Form>
    </FormContainer>
  );
};

export default AddLookForm;
