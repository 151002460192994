import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

const useAddToLook = () => {
  const addToLook = async (lookId, imageUrl) => {
    if (!lookId || !imageUrl) {
      console.error("No look selected or no image selected");
      return false; // Indicates failure due to missing data
    }

    const lookDocRef = doc(db, "looks", lookId);

    try {
      const lookDoc = await getDoc(lookDocRef);
      if (lookDoc.exists()) {
        const lookData = lookDoc.data();
        const updatedImages = [...(lookData.images || []), imageUrl];
        await updateDoc(lookDocRef, { images: updatedImages });
        console.log("Image added to look");
        return true; 
      } else {
        console.error("Look not found");
        return false; 
      }
    } catch (error) {
      console.error("Error updating look: ", error);
      return false; 
    }
  };

  return addToLook;
};

export default useAddToLook;






