import React, { useState } from "react";
import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import styled from "styled-components";
import useUserImages from "../customHooks/useUserImages";
import LookItemsModal from "./LookItemsModal";
import useLooks from "../customHooks/useLooks";
import useAddToLook from "../customHooks/useAddToLook";
import AddLookForm from "./AddLookForm";
import useSaveLook from "../customHooks/useSaveLook";

const LoadingMessage = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  font-size: normal;
  grid-column: 1 / -1;
  height: 30rem;
`;

const LooksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding-bottom: 0;
`;

const WardrobeContainer = styled.div`
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #333;
  margin: 0 0.5rem;
  border-radius: 5px;
  overflow: auto;
`;

const WardrobeHeader = styled.div`
  padding: 1rem;
`;

const LookSquare = styled.div`
  display: grid;
  object-fit: cover;
  border-radius: 5px;
  align-items: stretch;
  border: 1px solid #333;
  aspect-ratio: 1 / 1;
  text-align: center;
  padding: 1rem;
  align-items: center;
  word-break: break-word;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
`;

const ActionButton = styled.button`
  display: flex;
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: start;
  border: none;
  aspect-ratio: 1 / 1;
  background: white;
`;

const ButtonText = styled.span`
  display: block;
  text-align: center;
  font-size: large;
`;

const Looks = ({ onBackClick, user }) => {
  const [selectedLookItems, setSelectedLookItems] = useState([]);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
  const [currentLookId, setCurrentLookId] = useState(null);
  const [isAddLookFormVisible, setIsAddLookFormVisible] = useState(false);

  const { looks, loading, error, addNewLook } = useLooks(user);
  const {
    userImages,
    loading: loadingUserImages,
    error: errorUserImages,
  } = useUserImages(user);
  const addToLook = useAddToLook();
  const saveLook = useSaveLook(user);

  const handleAddLookClick = () => {
    setIsAddLookFormVisible(true);
  };

  const handleAddLookFormSave = async (lookData) => {
    const newLook = await saveLook(lookData);
    if (newLook) {
      setIsAddLookFormVisible(false);
      addNewLook(newLook);
    }
  };

  const openItemsModal = async (lookId) => {
    try {
      const lookDocRef = doc(db, "looks", lookId);
      const lookDoc = await getDoc(lookDocRef);

      if (!lookDoc.exists()) {
        throw new Error("Look not found");
      }

      setSelectedLookItems(lookDoc.data().images || []);
      setCurrentLookId(lookId);
      setIsItemsModalOpen(true);
    } catch (error) {
      console.error("Error opening modal:", error);
    }
  };

  const closeItemsModal = () => {
    setIsItemsModalOpen(false);
    setCurrentLookId(null);
  };

  return (
    <div>
      <WardrobeContainer>
        {loading || loadingUserImages ? (
          <LoadingMessage>Loading...</LoadingMessage>
        ) : error || errorUserImages ? (
          <div>Error: {error || errorUserImages}</div>
        ) : (
          <>
            <WardrobeHeader>LOOKS</WardrobeHeader>
            <LooksGrid>
              {looks.map((look) => (
                <LookSquare
                  key={look.id}
                  onClick={() => openItemsModal(look.id)}
                >
                  {look.lookName}
                </LookSquare>
              ))}
            </LooksGrid>
          </>
        )}
        {isItemsModalOpen && (
          <LookItemsModal
            looks={looks}
            selectedLookItems={selectedLookItems}
            setSelectedLookItems={setSelectedLookItems}
            userImages={userImages}
            currentLookId={currentLookId}
            onAddToLook={addToLook}
            onClose={closeItemsModal}
          />
        )}
      </WardrobeContainer>
      <ButtonContainer>
        <ActionButton onClick={handleAddLookClick}>
          <ButtonText>ADD</ButtonText>
        </ActionButton>
        <ActionButton>
          <ButtonText></ButtonText>
        </ActionButton>
        <ActionButton onClick={onBackClick}>
          <ButtonText>BACK</ButtonText>
        </ActionButton>
      </ButtonContainer>
      {isAddLookFormVisible && (
        <AddLookForm
          onSave={handleAddLookFormSave}
          onCancel={() => setIsAddLookFormVisible(false)}
        />
      )}
    </div>
  );
};

export default Looks;
