// LoginPage.js
import React from 'react';
import styled from 'styled-components';
import { auth, googleProvider } from '../config/firebase';  // Adjust the path if needed
import { signInWithPopup } from "@firebase/auth";
import { doc, setDoc, getFirestore } from "@firebase/firestore";

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;  // Takes the full height of the viewport
    background-color: #f4f4f4;  // A light gray background
`;

const LoginButton = styled.button`
    padding: 15px 30px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    background-color: #4285F4;  // Google's blue color
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #357ABD;  // A slightly darker blue for hover effect
    }
`;

const LoginPage = () => {
    const handleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
    
            // Save user data to Firestore
            const userRef = doc(getFirestore(), "users", user.uid);  // "users" is the collection name
            const userData = {
                displayName: user.displayName,
                email: user.email,
                photoURL: user.photoURL  // This is the profile picture URL
            };
            await setDoc(userRef, userData, { merge: true });
    
            console.log("Logged in with Google and saved user data to Firestore!");
    
        } catch (error) {
            console.error("Error logging in with Google:", error.message);
        }
    };

    return (
        <LoginContainer>
            <LoginButton onClick={handleLogin}>Log in with Google</LoginButton>
        </LoginContainer>
    );
}

export default LoginPage;