import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemSelectedModal = ({ isOpen, looks, onSave, onCancel }) => {
  const [selectedLookId, setSelectedLookId] = useState("");

  const handleSaveClick = () => {
    onSave(selectedLookId);
  };

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <select 
          defaultValue=""
          onChange={(e) => setSelectedLookId(e.target.value)}
        >
          <option value="" disabled>Select look</option>
          {looks.map((look, index) => (
            <option key={index} value={look.id}>{look.lookName}</option>
          ))}
        </select>
        <button onClick={handleSaveClick}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ItemSelectedModal;