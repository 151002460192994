import { useState, useEffect } from 'react';
import { db } from "../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const useLooks = (user) => {
  const [looks, setLooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchLooks = async () => {
      try {
        const q = query(collection(db, "looks"), where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const fetchedLooks = querySnapshot.docs.map(doc => ({
          id: doc.id, 
          ...doc.data()
        }));
        setLooks(fetchedLooks);
      } catch (err) {
        console.error("Error fetching looks:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLooks();
  }, [user]); // Dependency array now includes user

  // Function to add a new look to the state
  const addNewLook = (newLook) => {
    setLooks(currentLooks => [...currentLooks, newLook]);
  };

  return { looks, loading, error, addNewLook };
};

export default useLooks;
