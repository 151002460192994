import styled from "styled-components";

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
`;

const SquareButton = styled.button`
  display: flex;
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: start;
  border: 1px solid #333;
  aspect-ratio: 1 / 1;
  background: white;
`;

const ButtonText = styled.span`
  display: block;
  text-align: center;
  font-size: large;
`;

const ButtonGroup = ({ onAddLookClick, onLooksClick }) => {
  return (
    <ButtonContainer>
      <SquareButton onClick={onLooksClick}>
        <ButtonText>LOOKS</ButtonText>
      </SquareButton>
      <SquareButton>
        <ButtonText>CHAT</ButtonText>
      </SquareButton>
      <SquareButton>
        <ButtonText>STYLIST</ButtonText>
      </SquareButton>
    </ButtonContainer>
  );
};

export default ButtonGroup;
