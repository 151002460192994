import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MyCustomFont';  // This can be any name you choose
    src: url('/assets/fonts/HelveticaNeueLTPro-MdCn.otf') format('opentype');
  }

  body, button {
    font-family: 'MyCustomFont', sans-serif; // Use the name you defined above
  }
`;

export default GlobalStyle;


