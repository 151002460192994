import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { signOut } from "@firebase/auth";
import { auth } from "../config/firebase";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem; // Padding added to all sides
  background-color: transparent; // Set the background to transparent
`;

const UserProfilePicture = styled.div`
  width: 2rem; // Adjust as needed for desired size
  height: 2rem; // Make it square and same height as SearchInput
  background-image: url("/pic.png");
  background-size: cover; // To ensure image covers the entire div
  background-position: center; // Center the image
  border: 1px solid #333;
  border-radius: 5px; // Slightly rounded corners
  margin-right: 0.5rem;
`;

const SearchContainer = styled.div`
  position: relative;
  // width: 89%;
  flex-grow: 1;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 2.1rem; // This makes it the same height as the UserProfilePicture
  padding: 0 0.5rem;
  border: 1px solid #333;
  border-radius: 5px; // Slightly rounded corners, same as UserProfilePicture
  box-sizing: border-box;
  text-align: left;
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0; // Align it to the right edge of the input
  top: 0; // Align it to the top edge of the input
  height: 100%; // Make it cover the full height of the input
  padding: 0.2rem 0.5rem 0rem 0.5rem; // Some horizontal padding for the text
  background: none;
  border: none;
  font-size: small;
  color: #000;
  cursor: pointer;
  opacity: 0.7; // Optional: to make it slightly transparent
  transition: opacity 0.2s ease; // Smooth transition for hover effect

  &:hover {
    opacity: 1; // Brighten it up when hovered
  }
`;
const Header = ({ user, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState(""); // <-- add this line

  const handleSearchClick = () => {
    onSearch(searchTerm); // <-- use the onSearch prop here
  };

  const handleProfileClick = () => {
    // This will show a popup/dialog asking the user to confirm the action
    if (window.confirm("Are you sure you want to log out?")) {
      // Perform logout action using Firebase Auth
      signOut(auth)
        .then(() => {
          console.log("User signed out!");
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    }
  };

  return (
    <HeaderWrapper>
      <UserProfilePicture
        onClick={handleProfileClick}
        style={{
          backgroundImage: `url(${
            user ? user.photoURL : "/default_image_path.png"
          })`,
        }}
        alt="User"
      />{" "}
      <SearchContainer>
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton onClick={handleSearchClick}>SEARCH</SearchButton>
      </SearchContainer>
    </HeaderWrapper>
  );
};

export default Header;
