import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
`;

const ActionButton = styled.button`
  display: flex;
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: start;
  border: none;
  aspect-ratio: 1 / 1;
  background: white;
`;

const ButtonText = styled.span`
  display: block;
  text-align: center;
  font-size: large;
`;

const WardrobeButtonGroup = ({ onAddClick, onBackClick, onUploadClick }) => {
  return (
    <ButtonContainer>
      <ActionButton onClick={onUploadClick}>
        <ButtonText>ADD</ButtonText>
      </ActionButton>
      <ActionButton>
        <ButtonText></ButtonText>
      </ActionButton>
      <ActionButton onClick={onBackClick}>
        <ButtonText>BACK</ButtonText>
      </ActionButton>
    </ButtonContainer>
  );
};

export default WardrobeButtonGroup;
