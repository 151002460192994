import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
  align-items: stretch;
`;

const Image = styled.img`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  object-fit: cover;
  border-radius: 5px;
  max-width: 100%;
  justify-content: space-between;
  align-items: stretch;
  justify-items: start;
  border: 1px solid #333;
  aspect-ratio: 1 / 1;
`;

const ImageGrid = ({ userImages, openModal }) => {
  return (
    <Grid>
      {userImages.map((imgUrl, index) => (
        <Image
          key={index}
          src={imgUrl}
          alt="User uploaded content"
          onClick={() => openModal(imgUrl)}
        />
      ))}
    </Grid>
  );
};

export default ImageGrid;
