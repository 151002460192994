import React from "react";
import styled from "styled-components";
import { db } from "../config/firebase";
import { useState } from "react";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { useEffect } from "react";

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 5vw;
  justify-content: center;
`;

const TagItem = styled.span`
  background-color: black;
  color: white;
  border-radius: 1vw;
  padding: 1vw 2vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
  font-size: 3vw;
`;

const LookItemsModalContainer = styled.div`
  height: 37rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: white;
  border: 1px solid #333;
  border-radius: 5px;
  z-index: 10;
  margin: -0.55rem 1rem;
  cursor: pointer;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  padding: 1rem;
  flex-grow: 1;
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1rem;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%; /* Ensure the image covers the entire item area */
  object-fit: cover; /* Resize the image to cover the area, maintaining aspect ratio */
  border-radius: 1vw;
  border: 1px solid #333;
  grid-template-columns: repeat(3, 1fr);
  aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
`;

const WardrobeImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw; /* Gap between rows and columns */
  padding-top: 1rem;
  object-fit: cover;
  border-radius: 1vw;
  aspect-ratio: 1 / 1;
`;

const SaveButton = styled.button`
  padding: 1rem 1rem 2rem 1rem;
  width: 100%;
  background: none;
  cursor: pointer;
  text-align: center;
  font-size: large;
  border: none;
`;

const TagInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
  margin-top: -1vw;
  border: 1px solid #333;
  border-radius: 5px;
  background: white;
`;

const TagInput = styled.input`
  flex-grow: 1;
  padding: 3vw;
  border: none;
  border-radius: 2vw;
  &:focus {
    outline: none;
  }
`;

const AddTagButton = styled.span`
  padding: 0.2rem 0.5rem 0rem 0.5rem;
  background-color: transparent;
  color: #333;
  cursor: pointer;
  font-size: small;
  border-radius: 0 1vw 1vw 0;
  &:hover {
    color: #555;
  }
`;

const LookItemsModal = ({
  looks,
  selectedLookItems,
  setSelectedLookItems,
  userImages,
  currentLookId,
  onAddToLook,
  onClose,
}) => {
  const currentLook = looks.find((look) => look.id === currentLookId);
  const currentLookName = currentLook ? currentLook.lookName : "";

  const [tag, setTag] = useState("");
  const [lookTags, setLookTags] = useState([]);

  useEffect(() => {
    const fetchLookDetails = async () => {
      const lookDocRef = doc(db, "looks", currentLookId);
      const lookDocSnap = await getDoc(lookDocRef);
      if (lookDocSnap.exists()) {
        setLookTags(lookDocSnap.data().lookTags || []);
      }
    };

    if (currentLookId) {
      fetchLookDetails();
    }
  }, [currentLookId]);

  const handleAddTag = async () => {
    if (!tag.trim()) return;

    const lookDocRef = doc(db, "looks", currentLookId);
    try {
      await updateDoc(lookDocRef, {
        lookTags: arrayUnion(tag.trim()),
      });
      console.log("Tag added to the look");
      setLookTags((prevTags) => [...prevTags, tag.trim()]);
    } catch (error) {
      console.error("Error adding tag to the look: ", error);
    }

    setTag("");
  };

  const handleAddItemClick = async (imageUrl) => {
    const confirmAdd = window.confirm(
      "Do you want to add this item to the look?"
    );
    if (confirmAdd) {
      const success = await onAddToLook(currentLookId, imageUrl);
      if (success) {
        setSelectedLookItems((prevItems) => [...prevItems, imageUrl]);
      }
    }
  };

  return (
    <LookItemsModalContainer>
      <ScrollableContent>
        {currentLookName.toUpperCase()}
        <ItemsGrid>
          {selectedLookItems.map((itemUrl, index) => (
            <ItemImage key={index} src={itemUrl} alt="Look Item" />
          ))}
        </ItemsGrid>
        <br />
        WARDROBE
        <WardrobeImagesGrid>
          {userImages.map((imgUrl, index) => (
            <div key={index} onClick={() => handleAddItemClick(imgUrl)}>
              <ItemImage src={imgUrl} alt="Wardrobe Item" />
            </div>
          ))}
        </WardrobeImagesGrid>
      </ScrollableContent>
      <TagInputContainer>
        <TagInput value={tag} onChange={(e) => setTag(e.target.value)} />
        <AddTagButton onClick={handleAddTag}>ADD TAG</AddTagButton>{" "}
      </TagInputContainer>
      <TagList>
        {lookTags.map((tag, index) => (
          <TagItem key={index}>{tag}</TagItem>
        ))}
      </TagList>
      <SaveButton onClick={onClose}>SAVE & CLOSE</SaveButton>
    </LookItemsModalContainer>
  );
};

export default LookItemsModal;
