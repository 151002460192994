import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";

const useSaveLook = (user) => {
    const saveLook = async (lookData) => {
      if (!user) {
        alert("You must be logged in to save a look.");
        return null;
      }
  
      try {
        const looksCollectionRef = collection(db, "looks");
        const docRef = await addDoc(looksCollectionRef, {
          ...lookData,
          createdAt: serverTimestamp(),
          userId: user.uid,
        });
  
        // Return the complete new look object
        return { id: docRef.id, ...lookData };
      } catch (error) {
        console.error("Error adding look: ", error);
        return null;
      }
    };
  
    return saveLook;
  };

  export default useSaveLook;