import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Wardrobe from "./components/Wardrobe";
import ButtonGroup from "./components/ButtonGroup";
import { auth } from "./config/firebase";
import LoginPage from "./components/LoginPage";
import GlobalStyle from "./theme/GlobalStyle";
import AddLookForm from "./components/AddLookForm";
import { collection, addDoc } from "firebase/firestore";
import Looks from "./components/Looks";
import styled from "styled-components";
import WardrobeButtonGroup from "./components/WardrobeButtonGroup";
import useSaveLook from "./customHooks/useSaveLook";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "@firebase/storage";
import { doc, setDoc, getDoc, getFirestore } from "@firebase/firestore";

function App() {
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [authInitialized, setAuthInitialized] = useState(false);
  const [isAddLookFormVisible, setIsAddLookFormVisible] = useState(false);
  const [showLooks, setShowLooks] = useState(false);
  const [showWardrobe, setShowWardrobe] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setAuthInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  const WardrobeButton = styled.button`
    width: calc(100% - 1rem);
    height: 30rem;
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    margin: 0 0.5rem;
    font-size: large;

    &:hover {
      background-color: rgba(51, 51, 51, 0.1);
    }
  `;

  const ButtonText = styled.span`
    text-align: center;
    line-height: 1.2;
  `;

  const handleBackFromWardrobe = () => {
    setShowWardrobe(false);
  };

  const handleUploadClick = () => {
    document.getElementById("wardrobe-upload-input").click();
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    const name = prompt("Enter item name:");
    if (name) {
      handleUpload(name, image);
    } else {
      console.warn("Upload canceled or no name provided.");
    }
  };

  const handleUpload = async (itemName, imageToUpload) => {
    if (imageToUpload && itemName) {
      const storage = getStorage();
      const uniqueImageName = `${imageToUpload.name}_${Date.now()}`;
      const storageRef = ref(
        storage,
        `images/${auth.currentUser.uid}/${uniqueImageName}`
      );

      const uploadTask = uploadBytesResumable(storageRef, imageToUpload);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading image:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Image URL: ", downloadURL);

          const itemDoc = await addDoc(
            collection(getFirestore(), "wardrobeItems"),
            {
              imageName: itemName,
              imageURL: downloadURL,
              userId: auth.currentUser.uid,
            }
          );
          console.log("Item Doc: ", itemDoc);

          const userDocRef = doc(getFirestore(), "users", auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          const updatedImageRefs = [
            ...(userDoc.data().imageRefs || []),
            itemDoc,
          ];
          console.log("Updated Image Refs: ", updatedImageRefs);

          await setDoc(
            userDocRef,
            { imageRefs: updatedImageRefs },
            { merge: true }
          );
        }
      );
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleBackFromLooks = () => {
    setShowLooks(false);
  };

  const saveLook = useSaveLook(user);

  const handleCancelLook = () => {
    setIsAddLookFormVisible(false);
  };

  return (
    <div>
      <GlobalStyle />
      {user ? (
        <>
          <Header user={user} onSearch={handleSearch} />
          {!showLooks ? (
            <>
              {!showWardrobe ? (
                <>
                  <WardrobeButton onClick={() => setShowWardrobe(true)}>
                    <ButtonText>WARDROBE</ButtonText>
                  </WardrobeButton>
                  <ButtonGroup
                    onAddLookClick={() => setIsAddLookFormVisible(true)}
                    onLooksClick={() => setShowLooks(true)}
                  />
                </>
              ) : (
                <>
                  <Wardrobe
                    user={user}
                    searchTerm={searchTerm}
                    authInitialized={authInitialized}
                  />
                  <WardrobeButtonGroup
                    onBackClick={handleBackFromWardrobe}
                    onUploadClick={handleUploadClick}
                  />
                  <input
                    type="file"
                    id="wardrobe-upload-input"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </>
              )}
              {isAddLookFormVisible && (
                <AddLookForm onSave={saveLook} onCancel={handleCancelLook} />
              )}
            </>
          ) : (
            <Looks user={user} onBackClick={handleBackFromLooks} />
          )}
        </>
      ) : (
        <LoginPage />
      )}
    </div>
  );
}

export default App;
